/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Review } from './Review';
import {
    ReviewFromJSON,
    ReviewFromJSONTyped,
    ReviewToJSON,
} from './Review';

/**
 * 
 * @export
 * @interface ReviewsConfig
 */
export interface ReviewsConfig {
    /**
     * 
     * @type {number}
     * @memberof ReviewsConfig
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewsConfig
     */
    reviewsCount?: number;
    /**
     * 
     * @type {Array<Review>}
     * @memberof ReviewsConfig
     */
    reviews?: Array<Review>;
}

/**
 * Check if a given object implements the ReviewsConfig interface.
 */
export function instanceOfReviewsConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReviewsConfigFromJSON(json: any): ReviewsConfig {
    return ReviewsConfigFromJSONTyped(json, false);
}

export function ReviewsConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewsConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'reviewsCount': !exists(json, 'reviewsCount') ? undefined : json['reviewsCount'],
        'reviews': !exists(json, 'reviews') ? undefined : ((json['reviews'] as Array<any>).map(ReviewFromJSON)),
    };
}

export function ReviewsConfigToJSON(value?: ReviewsConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rating': value.rating,
        'reviewsCount': value.reviewsCount,
        'reviews': value.reviews === undefined ? undefined : ((value.reviews as Array<any>).map(ReviewToJSON)),
    };
}

