/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Duration } from './Duration';
import {
    DurationFromJSON,
    DurationFromJSONTyped,
    DurationToJSON,
} from './Duration';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';
import type { MeetingPointDescription } from './MeetingPointDescription';
import {
    MeetingPointDescriptionFromJSON,
    MeetingPointDescriptionFromJSONTyped,
    MeetingPointDescriptionToJSON,
} from './MeetingPointDescription';

/**
 * 
 * @export
 * @interface ProductSetup
 */
export interface ProductSetup {
    /**
     * 
     * @type {Languages}
     * @memberof ProductSetup
     */
    name: Languages;
    /**
     * 
     * @type {MeetingPointDescription}
     * @memberof ProductSetup
     */
    seoTitle?: MeetingPointDescription | null;
    /**
     * 
     * @type {MeetingPointDescription}
     * @memberof ProductSetup
     */
    metaDescription?: MeetingPointDescription | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductSetup
     */
    categoriesIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductSetup
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductSetup
     */
    tags?: string;
    /**
     * 
     * @type {Languages}
     * @memberof ProductSetup
     */
    description?: Languages;
    /**
     * 
     * @type {Languages}
     * @memberof ProductSetup
     */
    note?: Languages;
    /**
     * 
     * @type {Duration}
     * @memberof ProductSetup
     */
    duration?: Duration;
    /**
     * 
     * @type {string}
     * @memberof ProductSetup
     */
    searchIndex: ProductSetupSearchIndexEnum;
}


/**
 * @export
 */
export const ProductSetupSearchIndexEnum = {
    Public: 'public',
    Authenticated: 'authenticated',
    Excluded: 'excluded'
} as const;
export type ProductSetupSearchIndexEnum = typeof ProductSetupSearchIndexEnum[keyof typeof ProductSetupSearchIndexEnum];


/**
 * Check if a given object implements the ProductSetup interface.
 */
export function instanceOfProductSetup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "searchIndex" in value;

    return isInstance;
}

export function ProductSetupFromJSON(json: any): ProductSetup {
    return ProductSetupFromJSONTyped(json, false);
}

export function ProductSetupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductSetup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': LanguagesFromJSON(json['name']),
        'seoTitle': !exists(json, 'seoTitle') ? undefined : MeetingPointDescriptionFromJSON(json['seoTitle']),
        'metaDescription': !exists(json, 'metaDescription') ? undefined : MeetingPointDescriptionFromJSON(json['metaDescription']),
        'categoriesIds': !exists(json, 'categoriesIds') ? undefined : json['categoriesIds'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'description': !exists(json, 'description') ? undefined : LanguagesFromJSON(json['description']),
        'note': !exists(json, 'note') ? undefined : LanguagesFromJSON(json['note']),
        'duration': !exists(json, 'duration') ? undefined : DurationFromJSON(json['duration']),
        'searchIndex': json['searchIndex'],
    };
}

export function ProductSetupToJSON(value?: ProductSetup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': LanguagesToJSON(value.name),
        'seoTitle': MeetingPointDescriptionToJSON(value.seoTitle),
        'metaDescription': MeetingPointDescriptionToJSON(value.metaDescription),
        'categoriesIds': value.categoriesIds,
        'label': value.label,
        'tags': value.tags,
        'description': LanguagesToJSON(value.description),
        'note': LanguagesToJSON(value.note),
        'duration': DurationToJSON(value.duration),
        'searchIndex': value.searchIndex,
    };
}

