/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarConfig
 */
export interface CalendarConfig {
    /**
     * 
     * @type {boolean}
     * @memberof CalendarConfig
     */
    showMonthAvailability?: boolean;
}

/**
 * Check if a given object implements the CalendarConfig interface.
 */
export function instanceOfCalendarConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarConfigFromJSON(json: any): CalendarConfig {
    return CalendarConfigFromJSONTyped(json, false);
}

export function CalendarConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'showMonthAvailability': !exists(json, 'showMonthAvailability') ? undefined : json['showMonthAvailability'],
    };
}

export function CalendarConfigToJSON(value?: CalendarConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'showMonthAvailability': value.showMonthAvailability,
    };
}

