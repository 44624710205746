import React, { memo } from "react";
import { useLazyComponent } from "../../../hooks";

export interface PublicFooterProps {
  translate: Translate;
  currentLanguageCode: LanguageCode;
  year: string;
}

interface LazyComponentProps {
  domain: Domain;
  subdomain: Subdomain;
}

const PublicFooter = ({
  domain,
  subdomain,
  ...props
}: PublicFooterProps & LazyComponentProps): React.ReactElement => {
  const LazyComponent = useLazyComponent<PublicFooterProps>(
    () => import(`./${domain}/${subdomain}/Public.Footer`),
    () => import(`./DefaultPublicFooter`),
    [domain, subdomain]
  );

  return <LazyComponent {...props} />;
};

export default memo(PublicFooter);
